import React, { useState } from "react";
import api from "../utils/axiosConfig";
import "../styles/TradeDetails.css";

const TradeDetails = () => {
  const [activeTab, setActiveTab] = useState("positions"); // Active tab state
  const [users, setUser] = useState("");
  const [model_name, setModel] = useState("");
  const [positions, setPositions] = useState({});
  const [tablePositions, setTablePositions] = useState([]);
  const [orders, setOrders] = useState({});
  const [tableOrders, setTableOrders] = useState([]);
  const [currentPositions, setCurrentPositions] = useState({});
  const [error, setError] = useState("");

  // Fetch current positions
  const fetchCurrentPositions = async () => {
    try {
      const response = await api.post("/api/fetch_currentPositions", { users, model_name });
      if (response.status === 200) {
        setCurrentPositions(response.data);
        setError("");
      } else {
        setCurrentPositions({});
        setError("No current positions found for the provided user.");
      }
    } catch (err) {
      setCurrentPositions({});
      setError("An error occurred while fetching current positions.");
    }
  };

  // Fetch positions
  const fetchPositions = async () => {
    try {
      const response = await api.post("/api/fetch_positions", { users });
      if (response.status === 200) {
        const data = response.data
        setTablePositions(Object.keys(data.instrumentToken).map((key) => {
          return Object.fromEntries(
            Object.entries(data).map(([field, values]) => [field, values[key]])
          );
        }));
        setPositions(data);
        setError("");
      } else {
        setPositions({});
        setError("No positions found for the provided user.");
      }
    } catch (err) {
      setPositions({});
      setError("An error occurred while fetching positions.");
    }
  };

  // Fetch orders
  const fetchOrders = async () => {
    try {
      const response = await api.post("/api/fetch_orders", { users });
      if (response.status === 200) {
        const data = response.data
        setTableOrders(Object.keys(data.orderId).map((key) => {
          return Object.fromEntries(
            Object.entries(data).map(([field, values]) => [field, values[key]])
          );
        }));
        setOrders(data);
        setError("");
      } else {
        setOrders({});
        setError("No orders found for the provided user.");
      }
    } catch (err) {
      setOrders({});
      setError("An error occurred while fetching orders.");
    }
  };

  // Handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setError("");
    if (tab === "currentPositions") {
      fetchCurrentPositions();
    } else if (tab === "positions") {
      fetchPositions();
    } else if (tab === "orders") {
      fetchOrders();
    }
  };

  return (
    <div className="trade-details-container">
      <h2>Trade Details</h2>

      {/* Input Section */}
      <div className="input-section">
        <input
          type="text"
          placeholder="Enter User"
          value={users}
          onChange={(e) => setUser(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter Model Name"
          value={model_name}
          onChange={(e) => setModel(e.target.value)}
        />
      </div>

      {/* Tabs */}
      <div className="tabs">
        <button
          className={activeTab === "currentPositions" ? "active-tab" : ""}
          onClick={() => handleTabClick("currentPositions")}
        >
          Current Positions
        </button>
        <button
          className={activeTab === "positions" ? "active-tab" : ""}
          onClick={() => handleTabClick("positions")}
        >
          Positions
        </button>
        <button
          className={activeTab === "orders" ? "active-tab" : ""}
          onClick={() => handleTabClick("orders")}
        >
          Orders
        </button>
      </div>

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Current Positions Tab */}
      {activeTab === "currentPositions" && (
        <div className="data-display">
          <h3>Current Positions</h3>
          {Object.entries(currentPositions).map(([key, value]) => (
            <div key={key} className="result-entry" >
              <div>
                <strong>{key}</strong>
              </div>
              <div>
                <span>Entry:</span>
                <pre> {typeof value.entry === "string" ? value.entry.replace(/,/g, ",\n") : JSON.stringify(value.entry || {}, null, 2)}</pre>
              </div>
              <div>
                <span>Partial Exits:</span>
                <pre> {typeof value.partial_exits === "string" ? value.partial_exits.replace(/,/g, ",\n") : JSON.stringify(value.partial_exits || {}, null, 2)}</pre>
              </div>

            </div>
          ))}
        </div>
      )}

      {/* Positions Tab */}
      {activeTab === "positions" && tablePositions.length > 0 && (
        <div className="data-display">
          <h3>Positions</h3>
          <table className="data-table">
            <thead>
              <tr>
                {Object.keys(positions).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tablePositions.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Orders Tab */}
      {activeTab === "orders" && tableOrders.length > 0 && (
        <div className="data-display">
          <h3>Orders</h3>
          <table className="data-table">
            <thead>
              <tr>
                {Object.keys(orders).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableOrders.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TradeDetails;
