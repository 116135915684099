import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ onLogout }) => (
  <nav
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      background: '#2c3e50',
      color: '#ecf0f1',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
      <Link to="/users" style={linkStyle}>
        Users
      </Link>
      <Link to="/models" style={linkStyle}>
        Models
      </Link>
      <Link to="/result" style={linkStyle}>
        Result
      </Link>
      <Link to="/tradeDetails" style={linkStyle}>
        Trade Details
      </Link>
      <Link to="/tickerData" style={linkStyle}>
        Ticker Data
      </Link>
      <Link to="/backtest" style={linkStyle}>
        Backtest
      </Link>
    </div>
    <button
      onClick={onLogout}
      style={{
        color: '#ecf0f1',
        background: '#e74c3c',
        border: 'none',
        padding: '8px 15px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight: 'bold',
        transition: 'background 0.3s',
      }}
      onMouseOver={(e) => (e.target.style.background = '#c0392b')}
      onMouseOut={(e) => (e.target.style.background = '#e74c3c')}
    >
      Logout
    </button>
  </nav>
);

const linkStyle = {
  color: '#ecf0f1',
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: '500',
  padding: '8px 12px',
  borderRadius: '4px',
  transition: 'background 0.3s',
};

export default Navbar;

