import React, { useState } from 'react';
import api from '../utils/axiosConfig';
import '../styles/Users.css';  // Correct path to the CSS file

const Users = () => {
  const [username, setUsername] = useState('');
  const [user, setUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [error, setError] = useState('');

  const fetchUser = async () => {
    try {
      const response = await api.post('/api/get_user', { username });
      if (response.status === 500) {
        setError('Error');
      } else {
        setUser(JSON.stringify(eval('(' + response.data.login_info + ')'), null, 2));
        // setUser(JSON.stringify(JSON.parse(response.data.login_info), null, 2));
        setError('');
      }
    } catch (err) {
      setUser(null);
      setError("An error occurred while fetching user.");
    }
  };
  const updateUser = async () => {
    try {
      const confirmUpdate = window.confirm("Are you sure to Update user?");
      if (confirmUpdate) {
        const login_info = JSON.parse(user);
        const response = await api.post('/api/update_user', { username, login_info });
        if (response.status === 500) {
          setError('Error');
        } else { setError(''); }
      }
    } catch (err) {
      setUser(null);
      setError("An error occurred while updating user.");
    }
  };
  const setnewUser = async () => {
    try {
      const confirmSet = window.confirm("Are you sure to Set User?");
      if (confirmSet) {
        const login_info = JSON.parse(user);
        const response = await api.post('/api/create_user', { username, login_info });
        if (response.status === 500) {
          setError('Error');
        } else { setError(''); }
      }
    } catch (err) {
      setUser(null);
      setError("An error occurred while seting user.");
    }
  };
  const deleteUser = async () => {
    try {
      const confirmDelete = window.confirm("Are you sure to delete?");
      if (confirmDelete) {
        const response = await api.post('/api/delete_user', { username });
        if (response.status === 500) {
          setError('Error');
        } else { setError(''); }
      }
    }
    catch (err) {
      setUser(null);
      setError("An error occurred while deleting user.");
    }
  };
  const fetchAllUsers = async () => {
    try {
      const response = await api.get('/api/get_all_users');
      setAllUsers(response.data);
      if (response.status === 500) {
        setError('Error');
      } else { setError(''); }
    } catch (err) {
      setAllUsers([]);
      setError("An error occurred while geting all users.");
    }
  };

  return (
    <div className="users-container">
      <h2>Users</h2>

      <div>
        <input
          type="text"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        {user && (
          <div>
            <h3>User logininfo (JSON)</h3>
            <textarea
              onChange={(e) => setUser(e.target.value)}
              value={user}
              rows="10"
              cols="50"
            />
          </div>
        )}

        <div className="button-section">
          <button onClick={fetchUser}>Get User</button>
          <button onClick={updateUser}>Update User</button>
          <button onClick={setnewUser}>Set New User</button>
          <button onClick={deleteUser}>Delete User</button>
        </div>
        <div className="button-section"><button onClick={fetchAllUsers}>Get All Users</button></div>

        {error && <p className="error-message">{error}</p>}

        {allUsers.length > 0 && (
          <div>
            <h3>All Users (JSON)</h3>
            <textarea
              readOnly
              value={JSON.stringify(allUsers, null, 2)}
              rows="100"
              cols="50"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;