import React, { useState } from "react";
import api from "../utils/axiosConfig"; // Replace with your Axios configuration
import "../styles/TickerData.css";

const TickerData = () => {
  const [tickerName, setTickerName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [timeFrame, setTimeFrame] = useState("");
  const [customTimeFrame, setCustomTimeFrame] = useState(""); // For custom time frame input
  const [error, setError] = useState("");

  const fetchTickerData = async () => {
    try {
      const response = await api.post("/api/fetch_ticker_data", {
        ticker_name: tickerName,
        start_date: startDate,
        time_frame: customTimeFrame || timeFrame
      });

      if (response.status === 200) {
        // response.data
        setError("");
      } else {
        setError("No data found for the provided parameters.");
      }
    } catch (err) {
      setError("An error occurred while fetching the ticker data.");
    }
  };

  return (
    <div className="ticker-container">
      <h2>Fetch Ticker Data</h2>

      <div className="input-section">
        <input
          type="text"
          placeholder="Enter Ticker Name"
          value={tickerName}
          onChange={(e) => setTickerName(e.target.value)}
        />
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
          <option value="">Select Time Frame</option>
          <option value="1m">1 Minute</option>
          <option value="1h">1 Hour</option>
          <option value="1d">1 Day</option>
        </select>

        <input
          type="text"
          placeholder="Custom Time Frame (e.g., 5m, 1d)"
          value={customTimeFrame}
          onChange={(e) => setCustomTimeFrame(e.target.value)}
        />

        <button onClick={fetchTickerData}>Get Data</button>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default TickerData;
