import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import Users from './components/Users';
import Navbar from './components/Navbar';
import Models from "./components/Models";
import Result from './components/Result'; 
import TradeDetails from "./components/TradeDetails";
import TickerData from "./components/TickerData";


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      {isAuthenticated ? (
        <>
          <Navbar onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<Navigate to="/users" replace />} />
            <Route path="/users" element={<Users />} />
            <Route path="/models" element={<Models />} />
            <Route path="/result" element={<Result />} /> 
            <Route path="/tradeDetails" element={<TradeDetails />} />
            <Route path="/tickerData" element={<TickerData />} />  

          </Routes>
        </>
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </Router>
  );
};

export default App;