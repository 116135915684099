import React, { useState } from 'react';
import api from '../utils/axiosConfig';
import '../styles/theme.css';

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    //e.preventDefault();
    try {
      const response = await api.post('/api/login', { username, password });
      localStorage.setItem('token', response.data.token);
      onLogin(); // Notify parent component of successful login
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return (
    <div>
      <h2>Admin Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br></br>
        <br></br>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br></br>
        <br></br>
        <button type="submit" onClick={handleLogin}>Login</button>
      </form>
    </div>
  );
};

export default LoginForm;
