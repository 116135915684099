import React, { useState, useEffect } from "react";
import api from "../utils/axiosConfig";
import "../styles/Models.css";


const Trades = () => {
  const [users, setUsers] = useState("");
  const [model_name, setModelName] = useState("");
  const [modelDetails, setModelDetails] = useState("");
  const [enable, setEnable] = useState(false);
  const [live, setLive] = useState(false);
  const [trades, setTrades] = useState([]);
  const [error, setError] = useState("");

  // Get trades
  const fetchTrade = async () => {
    try {
      if (model_name === "") {
        const response = await api.post("/api/user_trades", { users });
        if (response.status === 500) {
          setError('Error');
        } else {
          setTrades(response.data);
          setError('');
        }
      } else {
        const response = await api.post("/api/user_model_trade", { users, model_name });
        setModelDetails(JSON.stringify(JSON.parse(response.data.model_detail), null, 2));
        setLive(response.data.live)
        setEnable(response.data.enable)
        setError('');
      }
    } catch (err) {
      setTrades([]);
      setError(JSON.stringify(err));
    }
  };

  // Add a new trade
  const addTrade = async () => {
    const confirmAdd = window.confirm("Are you sure to Add?");
    if (confirmAdd) {
      try {
        const model_detail = JSON.parse(modelDetails)
        const response = await api.post("/api/create_trade", { users, model_name, model_detail, enable, live });
        if (response.status === 500) {
          setError('Error');
        } else {
          setError('');
        }
      } catch (err) {
        setError(JSON.stringify(err));
      }
    }
  };

  // Update an existing trade
  const updateTrade = async () => {
    const confirmUpdate = window.confirm("Are you sure to Update?");
    if (confirmUpdate) {
      try {
        const model_detail = JSON.parse(modelDetails)
        const response = await api.post("/api/update_trade", { users, model_name, model_detail, enable, live });
        if (response.status === 500) {
          setError('Error');
        } else {
          setError('');
        }
      } catch (err) {
        setError(JSON.stringify(err));
      }
    }
  };

  // Delete a trade
  const deleteTrade = async () => {
    const confirmDelete = window.confirm("Are you sure to delete?");
    if (confirmDelete) {
      try {
        const response = await api.post("/api/delete_trade", { users, model_name });
        if (response.status === 500) {
          setError('Error');
        } else {
          setError('');
        }
      } catch (err) {
        setError(JSON.stringify(err));
      }
    }
  };

  return (
    <div className="trades-container">
      <h2>Models</h2>

      {/* Input Fields */}
      <div className="input-section">
        <input
          type="text"
          placeholder="Enter User"
          value={users}
          onChange={(e) => setUsers(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter Model Name"
          value={model_name}
          onChange={(e) => setModelName(e.target.value)}
        />
        <textarea
          placeholder="Enter Model Details"
          value={modelDetails}
          onChange={(e) => setModelDetails(e.target.value)}
          rows="15"
        />
        <div>
          <label>
            <input
              type="checkbox"
              checked={enable}
              onChange={(e) => setEnable(e.target.checked)}
            />
            Enable
          </label>
          <label>
            <input
              type="checkbox"
              checked={live}
              onChange={(e) => setLive(e.target.checked)}
            />
            Live
          </label>
        </div>
      </div>

      {/* Buttons for Operations */}
      <div className="button-section">
        <button onClick={fetchTrade}>Get Models</button>
        <button onClick={addTrade}>Add Models</button>
        <button onClick={updateTrade}>Update Models</button>
        <button onClick={deleteTrade}>Delete Models</button>
      </div>




      {/* Display Errors */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Display Trades */}
      {trades.length > 0 && (
        <div className="trades-display">
          <h3>Trade Details</h3>
          <textarea
            readOnly
            value={JSON.stringify(trades, null, 2)} // Display trades as JSON
            rows="100"
            cols="100"
          />
        </div>
      )}

    </div>
  );
};

export default Trades;
