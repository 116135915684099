import React, { useState } from "react";
import api from "../utils/axiosConfig";
import "../styles/Result.css";//Result.css

const Result = () => {
  const [users, setUsers] = useState("");
  const [modelName, setModelName] = useState("");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");

  const fetchResults = async () => {
    try {
      const response = await api.post("/api/get_trade_results", { users, model_name: modelName });

      if (response.status === 200) {
        const sortedResults = response.data.sort((a, b) =>
          new Date(b.entry_datetime) - new Date(a.entry_datetime)
        );

        setResults(sortedResults);
        setError("");
      } else {
        setError("No results found for the provided users and model name.");
        setResults([]);
      }
    } catch (err) {
      setError("An error occurred while fetching trade results.");
      setResults([]);
    }
  };

  return (
    <div className="result-container">
      <h2>Results</h2>

      {/* Input Section */}
      <div className="input-section">
        <input
          type="text"
          placeholder="Enter Users"
          value={users}
          onChange={(e) => setUsers(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter Model Name"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
        />
        <button onClick={fetchResults}>Get Results</button>
      </div>

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Display Results */}
      {results.length > 0 && (
        <div className="results-display">
          {results.map((result, index) => (
            <div key={index} className="result-entry">
              <div className="result-field" style={{ display: "flex", gap: "1rem" }}>
                <span>Result {index + 1}</span>
                <span>{result.users}</span>
                <span>{result.model_name}</span>
                <span>{result.symbol}</span>
              </div>
              <div className="result-field">
                <strong>Entry Datetime:</strong> <span>{new Date(result.entry_datetime).toLocaleString()}</span>
              </div>
              <div className="result-field">
                <strong>Entry:</strong>
                <pre> {typeof result.entry === "string" ? result.entry.replace(/,/g, ",\n") : JSON.stringify(result.entry || {}, null, 2)}</pre>
              </div>
              <div className="result-field">
                <strong>Partial Exits:</strong>
                <pre>
                  {typeof result.partial_exits === "string" ? result.partial_exits.replace(/,/g, ",\n") : JSON.stringify(result.partial_exits || {}, null, 2)}</pre>

              </div>
              <div className="result-field">
                <strong>Exit Datetime:</strong> <span>{new Date(result.exit_datetime).toLocaleString()}</span>
              </div>
              <div className="result-field">
                <strong>Exit:</strong>
                <pre>{JSON.stringify(result.exit, null, 2)}</pre>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Result;
